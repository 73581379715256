/* :root {
    --main-primary: #002368;
    --main-default: #008c8c;
    --main-secondary: #25bece;
    --main-white: white;
    --main-white-dark: #eeeeee;
    --main-gray: gray;
    --main-gray-dark: #020410;
    --main-gray-light: #e6e8ec;
    --main-green: #18c3cd;
} */
#chat,
#subtitles {
    width: calc(100% - 80px);
    margin: 0 auto;
    padding-top: 10px;
    height: calc(100% - 20px);
}

#chat .box-subtitle,
#subtitles .box-subtitle {
    margin-top: 18px;
    height: calc(100% - 38px);
}

#chat .body-container p {
    background: white;
}

.header-container {
    border-bottom: 3px solid var(--component-header-option);
    padding: 0;
    height: 16px;
    background: transparent;
}

.header-title {
    color: var(--component-header-option);
    position: relative;
    top: 8px;
    padding-right: 10px;
    text-transform: uppercase;
    font-size: 1.3em;
}

#chat .header-title,
#chat .header-options {
    background: #f4f5f9;
}

#subtitles .header-title,
#subtitles .header-options {
    background: white;
}

.header-options {
    border-radius: 25px;
    border: 3px solid var(--component-header-option);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    position: relative;
    top: 8px;
}

.header-options button {
    color: var(--component-header-option);
    line-height: 0;
}

.header-options button svg {
    height: 25px;
}

.msg-user {
    display: block;
    text-align: right;
    padding-right: 0;
}

.msg-user .message-user {
    max-width: 80%;
    display: inline-flex;
    box-shadow: 4px 3px 12px -8px #2e2e2e;
    margin-bottom: 4px;
    margin-right: 12px;
}

.msg-user .date-chat {
    margin-right: 12px;
}

.msg-voxeet {
    display: block;
}

.msg-voxeet .date-chat {
    text-align: left;
    margin-left: 42px;
}

.msg-voxeet .message-chat {
    max-width: 80%;
    display: inline-flex;
    margin-bottom: 4px;
    box-shadow: -4px 3px 12px -8px #2e2e2e;
}

.tri-right.right-top:after {
    right: -12px;
    border: 12px solid;
    border-color: white transparent transparent transparent;
}

.tri-right.left-top:after {
    left: -12px;
    border: 12px solid;
    border-color: white transparent transparent transparent;
}

.wrapper-button-speech-chat .btn {
    background: transparent;
}

.wrapper-input-chat textarea {
    background: transparent;
    border-bottom: 1px solid var(--component-header-option);
    font-size: 1em;
}

.wrapper-input-chat textarea:focus,
.wrapper-input-subtitles textarea:focus {
    outline: none;
}

.wrapper-button-speech-chat .btn-green {
    background: var(--main-green);
}

.wrapper-input-subtitles textarea {
    background: transparent;
    border-bottom: 1px solid var(--component-header-option);
    font-size: 1em;
}

.wrapper-input-subtitles textarea::placeholder,
.wrapper-input-chat textarea::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--main-primary);
    opacity: 1; /* Firefox */
}

.wrapper-input-chat-group {
    align-items: center;
}

.wrapper-input-chat-group .wrapper-textarea-chat {
    height: 100%;
}

.wrapper-input-chat textarea {
    height: 70%;
}

.wrapper-input-subtitles {
    display: flex;
    width: 100%;
    align-items: flex-end;
}

.wrapper-input-subtitles .wrapper-textarea-subtitles {
    height: 100%;
}

.wrapper-input-subtitles textarea {
    height: 70%;
}
