.wrapper-components {
    position: relative;
    height: calc(100vh - 70px);
}

.div-controls {
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

footer {
    background: white;
    border-top: 2px solid var(--main-primary);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 5px;
}

.footer-item-btn {
    flex: 0.3;
    text-align: center;
}

.footer-item-btn .btn {
    width: 90%;
}

.footer-item-btn label {
    display: block;
}
